<template>
  <div>
    <div v-if="$route.params.error">
      <h1>We are sorry!</h1>
      <b>{{ $route.params.error.message }}.</b><br/>
      Try a new page by using the navigation bar up top.
    </div>
    <div v-else>
      <h1>We are sorry!</h1>
      <b>Page does not exist.</b><br/>
      Try a new page by using the navigation bar up top.
    </div>    
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: ['error'],
  mounted() {
    if (this.$route.path !== '/error') {
      this.$router.push({ name: 'Error' })
    }
  }
}
</script>